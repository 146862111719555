import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "styled-components"
import { Dialog } from "@reach/dialog"
import ImageScrollButton from "../components/ImageScrollButton"
import Button from "../components/Button"
import Filters from "../components/Filters"
import "@reach/dialog/styles.css"
import "./lightbox.css"
const LightboxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  padding: 1remcd documents;

  @media only screen and (min-width:481px) {
    grid-template-columns: repeat(4, 2fr);
  }
`
const PreviewButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
`
export default class Lightbox extends Component {
  static propTypes = {
    projectImages: PropTypes.array.isRequired, // eslint-disable-line
  }
  constructor(props) {
    super(props)
    this.state = {
      showLightbox: false,
      selectedImage: null,
      nextImageIndex: null,
      previousImageIndex: null,
      filterTrayOpen: false,
      filterSelection: "projectImages",
    }
  }

  render() {
    const { commercialImages, residentialImages } = this.props
    const projectImages = commercialImages.concat(residentialImages);
    const { selectedImage, showLightbox, filterTrayOpen } = this.state
    
    const getImageSet = (filterSelection) => {
      if (filterSelection === "commercialImages") {
        return commercialImages;
      } else if (filterSelection === "residentialImages") {
        return residentialImages
      }
      return projectImages;
    }

    const imageSet = getImageSet(this.state.filterSelection)
    const maxIndex = imageSet.length - 1;

    const handleNext = () => {
      if (this.state.nextImageIndex > maxIndex) {
        this.setState({ selectedImage: imageSet[0], nextImageIndex: 1, previousImageIndex: maxIndex})
      } else if (this.state.previousImageIndex === maxIndex) {
        this.setState({ selectedImage: imageSet[this.state.nextImageIndex], nextImageIndex: this.state.nextImageIndex + 1, previousImageIndex: 0 })
      }
      else {
        this.setState({ selectedImage: imageSet[this.state.nextImageIndex], nextImageIndex: this.state.nextImageIndex + 1, previousImageIndex: this.state.previousImageIndex + 1 })
      }
    };

    const handlePrevious = () => {
      if (this.state.previousImageIndex < 0) {
        this.setState({ selectedImage: imageSet[maxIndex], nextImageIndex: 0, previousImageIndex: maxIndex - 1 })
      } else if (this.state.nextImageIndex === 0) {
        this.setState({ selectedImage: imageSet[this.state.previousImageIndex], nextImageIndex: maxIndex, previousImageIndex: maxIndex - 2 })
      }
      else {
        this.setState({ selectedImage: imageSet[this.state.previousImageIndex], nextImageIndex: this.state.nextImageIndex - 1, previousImageIndex: this.state.previousImageIndex - 1 })
      }
    };

    const onClickFilterButton = () => {
      if (this.state.filterTrayOpen) {
        this.setState({filterTrayOpen: false})
      } else {
        this.setState({filterTrayOpen: true})
      }
    ;}

    const onClickFilterItem = (imageGroupName) => {
      this.setState({filterSelection: imageGroupName})
    }

    return (
      <>
      <div class="filter-container">
      <Button onClickHandler={onClickFilterButton} text="Filter"/>
      {filterTrayOpen &&
      <Filters filterSelection={this.state.filterSelection} onClickHandler={onClickFilterItem}/>}
      </div>
      <Fragment>
        <LightboxContainer>
          {getImageSet(this.state.filterSelection).map((image, index) => (
            <PreviewButton
              key={image.node.childImageSharp.fluid.src}
              type="button"
              onClick={() =>
                this.setState({ showLightbox: true, selectedImage: image, nextImageIndex: index + 1, previousImageIndex: index - 1 })
              }
            >
              <Img fluid={image.node.childImageSharp.fluid} />
            </PreviewButton>
          ))}
        </LightboxContainer>
        {showLightbox && (
          <Dialog allowPinchZoom={true} onDismiss={() => this.setState({ showLightbox: false, nextImageIndex: null, previousImageIndex: null})}>
            <div class="close-container">
              <button
                className={"close"}
                type="button"
                onClick={() => this.setState({ showLightbox: false, nextImageIndex: null, previousImageIndex: null})}
              >
                close x
              </button>
            </div>
              <Img fluid={selectedImage.node.childImageSharp.fluid} />
              <div class="scroll-buttons">
                <ImageScrollButton text={"< previous"} onChangeImage={handlePrevious}/>
                <ImageScrollButton onChangeImage={handleNext}/>
              </div>
          </Dialog>
        )}
      </Fragment>
      </>
    )
  }
}