import * as React from "react"
import PropTypes from "prop-types"
import "./button.css"

const Button = ({ text, onClickHandler }) => (
  <button class="standard-button" type="button" onClick={() => onClickHandler()}>
    {text}
  </button>
)

Button.propTypes = {
  text: PropTypes.string,
  onClickHandler: PropTypes.func,
}

Button.defaultProps = {
  text: ``,
  onClickHandler: null,
}

export default Button