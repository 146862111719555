import * as React from "react"
import PropTypes from "prop-types"
import "./imageScrollButton.css"

const ImageScrollButton = ({ text, onChangeImage }) => (
  <button 
    class="scroll-image" 
    type="button" 
    value={text}
    onClick={() => onChangeImage()}
  >{text}</button>
)

ImageScrollButton.propTypes = {
  text: PropTypes.string,
  onChangeImage: PropTypes.func
}

ImageScrollButton.defaultProps = {
  text: "next >",
  onChangeImage: null,
}

export default ImageScrollButton