import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectImages from "../components/ProjectImages"
import SectionHeading from "../components/SectionHeading"
import "./projects-page.css"
const backButtonText = "< Back to home"

const ProjectsPage = () => (
  <Layout>
    <Seo title="Projects" />
    <div class="gallery-container">
      <SectionHeading title={"Project Gallery"}/>
      <ProjectImages/>
      <br/>
      <Link to="/">{backButtonText}</Link>
    </div>
  </Layout>
)

export default ProjectsPage
