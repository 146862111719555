import * as React from "react"
import "./filters.css"
import * as copy from "../copy/copy.json"


const Filters = ({filterSelection, onClickHandler}) => 
  {
    
    const getFilterButtonClass = (currentFilter) => filterSelection === currentFilter ? "selected-filter-button" : "default-filter-button";

    return (
      copy.filters.map(filter => 
        (
          <>
            <button class="clear-filter" type="button" onClick={() => onClickHandler()}>Clear filters</button>
            <h4 key={filter.name}>{filter.name}</h4>
            <div class="filter-items-container">
              {filter.items.map(filterItem => 
              <>
              <button className={getFilterButtonClass(filterItem.imageGroupName)} key={filterItem.text} onClick={() => onClickHandler(filterItem.imageGroupName)}>{filterItem.text}</button> 
              </>
              )}
            </div>
          </>
        )
      )
    )
  }

export default Filters

