import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Lightbox from "./Lightbox"

const ProjectImages = () => (
  <StaticQuery
    query={graphql`
      query {
        residentialImages: allFile(filter: { sourceInstanceName: { eq: "residential" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        commercialImages: allFile(filter: { sourceInstanceName: {eq: "commercial" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Lightbox commercialImages={data.commercialImages.edges} residentialImages={data.residentialImages.edges} />}
  />
)
export default ProjectImages